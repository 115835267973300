.promotionNew {
    background: rgba(89, 183, 228, 0.2) !important;
    position: relative;
    padding: 50px 0px;
}

.image_cont {
    position: relative;
    overflow: hidden;
    min-height: 500px;
    background-image: url("../../public/images/sample_background.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    z-index: 0;
}
  
.image_cont img {
    position: absolute;
    width: 100%;
    height: 160%;
    object-fit: cover;
    bottom: 0;
}

@media (max-width: 1024px) {
    .image_cont {
        min-height: 300px;
    }
}

@media (max-width: 768px) {
    .promotionNew {
        padding: 15px 0px;
    }

    .image_cont {
        min-height: 250px;
        /* background-attachment: scroll;
        background-size: cover;  */
    }

    .image_cont img {

        height: 60%;
    }
}

.countdown {
  font-size: 2rem;
  color: rgb(253, 72, 72);
  margin-left: 1rem;
  font-size: 1.5vw;
}

.hotded {
  font-size: 1.5rem;
}

.img_hovf {
  overflow: hidden;
}

.img_hover:hover {
  scale: 110%;
  transition: 0.5s;
}

.form-control:focus {
  color: #000000;
  box-shadow: 0 0 0 0rem rgba(255, 255, 255, 0.25);
  text-decoration: none;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

/* ****************** Header ****************** */
.loginHide {
  display: none;
}

.logo_con {
  margin-left: 2%;
}

.red_search {
  width: 5%;
  margin-top: 0.5rem;
}

.red_search_img {
  /* width: 2.3rem;
  height: 2.3rem; */
  /* margin-top: 1rem;
  margin-left: 1rem; */
  width: 30px;
  height: 30px;
}

.quote_product_modal .modal-content {
  width: 150%;
  margin-top: 20%;
  left: -30%;
}

/* ***********  users initial ********** */
.users_initial_dropdown:hover .users_dropdown {
  display: block;
}

.users_initial_dropdown {
  float: left;
  overflow: hidden;
  /* padding-left: 2rem;
  padding-right: 0.7rem; */
  align-self: center;
}

.mining_cart {
  margin-left: 2rem;
}

.users_dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 120px;
  /* right: 1; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  text-align: left;
  border-radius: 8%;
  font-weight: bold;
  min-width: 92px;
}

.users_column > li {
  margin: 0.3rem;
}

.users_initial {
  border-radius: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 500;
  color: #1e4881;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #1e4881;
  background-color: lightgrey;
}

/* *********      users initial Hexagon       ************ */

.Avtbox {
  position: relative;
  height: 45px;
  width: 40px;
  background-image: linear-gradient(#68d4fe, #1e4881);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e4881;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.Avtbox,
.Avtbox:before {
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.Avtbox_users_initial {
  color: white;
  font-weight: 500;
}

.Avtbox_users_initial:hover {
  color: white;
}

.Avtbox_admin {
  position: relative;
  height: 45px;
  width: 40px;
  background-image: linear-gradient(#62e75e, #035500);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e4881;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.Avtbox_admin,
.Avtbox_admin:before {
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

/* .Avtbox:before {
  position: absolute;
  content: '';
  top: 2.5px;  border width
  left: 2.5px;  border width
  height: calc(100% - 5px);  100% - (2 * border width)
  width: calc(100% - 5px);  100% - (2 * border width)
  background: lightgrey;
  z-index: -1;
} */

/* ***********  Cart dropdown ********** */
.cart_dropdown:hover .cart_dropdown_box {
  display: block;
}

.cart_dropdown {
  float: left;
  /* overflow: hidden; */
  /* padding-left: 1rem; */
  /* margin-right: 3rem; */
  align-self: center;
}

.mining_cart {
  margin-left: 2rem;
}

.cart_dropdown_box {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 570px;
  right: 0;
  box-shadow: 2px 8px 16px 2px rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.w3c_dropdown:hover .cart_dropdown_box {
  display: block;
}

/* ***********  Weather dropdown ********** */
.weather_dropdown:hover .weather_dropdown_box {
  display: block;
}

.weather_dropdown {
  float: left;
  overflow: hidden;
  /* padding-left: 1rem;
  margin-right: 3rem; */
  align-self: center;
}

.mining_cart {
  margin-left: 2rem;
}

.weather_dropdown_box {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 400px;
  right: 7%;
  box-shadow: 2px 8px 16px 2px rgba(0, 0, 0, 0.4);
  z-index: 99;
  padding: 0 1rem;
  border-radius: 1.5rem;
}

.w3c_dropdown:hover .weather_dropdown_box {
  display: block;
}

/* ******* quote dropdown ******* */
.quote_dropdown {
  float: left;
  /* overflow: hidden; */
  align-self: center;
  /* padding-left: 1rem; */
}

.miningCart {
  position: relative;
}

.badge_quote {
  position: absolute;
  top: 15%;
  right: -5%;
  transform: translate(50%, -50%);
  font-size: 0.6rem;
}

.badge_quote.bg-danger {
  background-color: #dc3545;
  color: white;
}

/* *******  ******* */
.hd_bgc {
  background-image: url("../../public/images/headerBackground_5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .hd_bgc {
    background-size: contain;
  }
}

/* .hd_bgc {
  background: #dde4e9;
  background-image: linear-gradient(to right, rgba(255,255,255), rgba(0, 117, 255, 0.5));
} */

/* .hd_bgc {
  background-color: #1e4881;
  background-image:url("../../public/images/logo/background-hexagon.jpg");
  background-size: cover;
  background-repeat: no-repeat;
} */

/* .hd_bg_container {
  position: relative;
}

.hd_bg_container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4); 
}

.hd_bg_container img {
  position: relative;
  z-index: -122; 
} */

.CTL_btn {
  background-color: #1e4881;
  border: #1e4881 solid 1px;
}

.CTL_btn:hover {
  border: #5c8dd1 solid 1px;
  background-color: #5c8dd1;
  /*   background-image: linear-gradient(#68D4FE, #1E4881); */
}

.nav_CTL {
  width: 60%;
}

.hd_c {
  color: #1e4881;
  /*     background-color: #1E4881; */
  font-weight: bold;
}

.hd_c:hover {
  color: black;
  cursor: pointer;
}

.login {
  text-align: right;
  position: absolute;
  right: 18%;
  z-index: 99;
}

.input_search {
  margin-left: 8%;
  width: 30%;
}

#responsive-navbar-nav {
  padding-left: 5%;
}

.w3c_dropdown:hover .users_dropdown {
  display: block;
}

/* ****** header LOIG hexagon ****** */
.hexagontext {
  width: 65%;
  height: 30%;
  margin-left: 10%;
}

.rotate {
  animation: rotation 4s linear;
  width: 15%;
  height: 15%;
}

.rotate-mobile {
  width: 15%;
  height: 15%;
}

.rotate-mobile.start {
  animation: rotation 4s linear infinite;
}

.rotate-mobile.paused {
  animation-play-state: paused;
}

@keyframes rotation {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.infinite {
  animation-iteration-count: infinite;
}

.rotate:hover {
  animation: normal;
}

/* ****************** Navbar ****************** */

#responsive-navbar-nav {
  /*     position: absolute;
    right: 4rem; */
  /*     margin-top: 4rem; */
  margin-left: 12rem;
}

.nav_bgc {
  background: #073474;
  /* height:3em; */
  font-weight: bold;
}

.nav_c {
  color: white;
  font-weight: bold;
}

#basic-navbar-nav {
  width: 100vw;
  text-align: center;
}

.nav_c:hover {
  color: rgb(22, 220, 255);
}

.nav {
  margin-bottom: 0;
}

.dropdown {
  position: static;
}

.brand {
  display: block;
}

#basic-nav-dropdown {
  display: block;
  height: 100%;
}

.dropdown-menu {
  width: 80%;
  margin-left: 10%;
  text-align: left;
}

.dropdown-menu > a {
  display: inline-block;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.NavMDB_dropdown {
  width: 900%;
  /*   position: absolute;
  top: 80%;
  left: 50px; */
}

/* Navbar container */
.w3c_nav {
  width: 87%;
  justify-content: space-evenly;
  margin-left: 5%;
}

/* Links inside the navbar */
/* .w3c_nav a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 0px 16px;
  text-decoration: none;
} */

/* The dropdown container */
.w3c_dropdown {
  float: left;
  overflow: hidden;
  width: 12%;
}

/* Dropdown button */
.dropbtn {
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
  background-color: inherit;
  font: inherit;
  border: #073474;
  border-width: 0.5px;
  border-bottom: 0px;
  border-style: solid;
  cursor: pointer;
}

.dropbtn a {
  font-size: 16px;
  color: white;
  text-align: center;
  text-decoration: none;
}

/* Add a red background color to navbar links on hover */

.w3c_dropdown:hover .dropbtn {
  /* color: rgb(22, 220, 255); */

  border: #ffffff;
  background-color: #032a61;
  border-width: 0.5px;
  border-bottom: 0px;
  border-style: solid;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  margin-left: 5.8%;
  top: 2.1rem;
  width: 85.5%;
  height: 100%;
  left: 0;
  z-index: 99;
  font-weight: normal;
  border-top: #ffffff;
  border-top-width: 0.5px;
  border-top-style: solid;
  color: #1e4881;
}

/* Show the dropdown menu on hover FOR BRAND */
.w3c_dropdown:hover .dropdown-content-brand {
  display: block;
}

.dropdown-content-brand {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 14%;
  top: 2.1rem;
  margin-left: -25px;
  z-index: 99;
}

.dropdown-content-brand a {
  padding: 4px;
  text-decoration: none;
}

/* brand_img */
.brand_img {
  width: 100%;
}

.img_hvf {
  overflow: hidden;
}

.brand_img:hover {
  scale: 105%;
  /* border-width:1px;
  border-color: #000000;
  border-style: solid; */
}

/* Show the dropdown menu on hover */
.w3c_dropdown:hover .dropdown-content {
  display: block;
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  background-color: #011f49;
  height: auto;
  min-height: 100px;
}

/* Style links inside the columns */
.column a {
  float: none;
  color: #ffffff;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 0;
}
.subCat_visitor {
  float: none;
  color: #ffffff;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 0;
  font-size: large;
  font-weight: bolder;
  color: rgb(22, 220, 255);
}
.subCat_visitor:hover {
  cursor: pointer;
  color: rgb(22, 220, 255);
  text-decoration: underline;
}

/* Add a background color on hover */
.childCat a:hover,
.subCat > a:hover {
  text-decoration: underline;
}

.subCat a {
  font-size: large;
  font-weight: bolder;
  color: rgb(22, 220, 255);
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column > li {
  /*   line-height: 0.1rem; */
  margin: 0.5rem;
  list-style-type: none;
}

/* ****** multi level dropdown ****** */

.parent {
  display: block;
  position: relative;
}

.parent:hover > .child {
  display: list-item;
  position: absolute;
  width: 60%;
}

.parent:hover {
  background-color: #1e4881;
}

.parentA {
  width: 45%;
  float: left;
}

.parentA:hover {
  text-decoration: underline;
}

.child a {
  margin: 5px;
  text-decoration: none;
}

.child {
  display: none;
  background-color: #1e4881;
  z-index: 5;
  left: 40%;
}

.child li {
  width: 100%;
}

.child li a {
  color: #ffffff;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0px;
  min-width: 14em;
}

ul ul {
  left: 100%;
  top: 0;
}

li:hover {
  color: white;
}

.parent li:hover {
  text-decoration: underline;
}

/* .expand {
	font-size: 12px;
	float: right;
} */

/* ****************** slogan ****************** */
.slogan {
  text-align: center;
}

/*********************COntainer*************************/
.content-container {
  /* padding-bottom: 7rem; */
}

/* ****************** Footer ****************** */

.foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 220px;
  background: #073474;
}

.foot a {
  max-width: max-content;
}

/* .ft_bgc {
  background: #073474;
  height: 100%;
} */

.first-col {
  padding-top: 15px;
  padding-left: 12%;
  font-weight: bolder;
  color: #ffffff;
  text-align: left;
}

.first-col h6 {
  text-decoration: underline;
}

.ft_c {
  color: white;
  font-weight: lighter;
}

.ft_c:hover {
  text-decoration: underline;
}

.hover-image-container {
  position: relative;
  display: inline-block;
}

.hover-image-container img.qr-code {
  position: absolute;
  width: 100px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  display: none;
}

.hover-image-container:hover img.qr-code {
  display: block;
}

.footer-btns {
  display: flex;
  width: 100%;
  max-width: 100px;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.footer-btns svg {
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
}

.linkedin svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* ****************** Carousel ****************** */
/* .carousel_ft img{
    height: 400px;
} */

.brand_title {
  /*     display: block;
    float: right;
    margin-bottom: 14rem; */
  color: black;
}

.brand_detail {
  /*     display: block;
    float: right;
    margin-bottom: 10rem; */
  color: black;
}

.carousel {
  padding-top: 1px;
  background-color: #dde4e9;
}

.carousel-inner > .item {
  position: relative;
  max-height: 600px;
}

.carousel-caption {
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translateY(-50%);
}

aside {
  text-align: left;
}

/* *************    Pagination   ************ */
.page-item > a {
  color: #1e4881;
}

.pagination > .active > span {
  background-color: #5c8dd1 !important;
  border-color: #5c8dd1 !important;
}

.pagination_productlist {
  width: 70%;
}

.pagination_productlist {
  font-size: 0.5rem;
  /* Adjust the font size as needed */
}

.pagination_productlist .page-link {
  padding: 0.1rem 0.5rem;
  /* Adjust the padding as needed */
}

/* .page-link {
  background-color: #5c8dd1;
} */

/* *************    Cart 购物车加减    ************* */
.btn-group {
  box-shadow: none;
}

.btn-group:hover {
  box-shadow: none;
}

.btn_jj {
  border: none;
  background-color: transparent;
}

.color-pattern {
  background-color: #e5e5f7;
  opacity: 0.2;
  background-image: linear-gradient(
      30deg,
      #bee7fc 12%,
      transparent 12.5%,
      transparent 87%,
      #bee7fc 87.5%,
      #bee7fc
    ),
    linear-gradient(
      150deg,
      #bee7fc 12%,
      transparent 12.5%,
      transparent 87%,
      #bee7fc 87.5%,
      #bee7fc
    ),
    linear-gradient(
      30deg,
      #bee7fc 12%,
      transparent 12.5%,
      transparent 87%,
      #bee7fc 87.5%,
      #bee7fc
    ),
    linear-gradient(
      150deg,
      #bee7fc 12%,
      transparent 12.5%,
      transparent 87%,
      #bee7fc 87.5%,
      #bee7fc
    ),
    linear-gradient(
      60deg,
      #bee7fc77 25%,
      transparent 25.5%,
      transparent 75%,
      #bee7fc77 75%,
      #bee7fc77
    ),
    linear-gradient(
      60deg,
      #bee7fc77 25%,
      transparent 25.5%,
      transparent 75%,
      #bee7fc77 75%,
      #bee7fc77
    );
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

/* *********      Scroll To Top Button       ************ */
.scroll-to-top {
  position: fixed;
  bottom: 20%;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.bi-arrow-up-circle-fill.visible {
  opacity: 1;
}

.bi-arrow-up-circle-fill {
  font-size: 35px;
  color: #1e4881;
  background-color: white;
}

.bi-arrow-up-circle-fill:hover {
  color: white;
  background-color: #1e4881;
}

/* *************** VISITOR PART *************** */

.column_visitor a {
  float: none;
  /* color: #ffffff; */
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.column_visitor {
  float: left;
  width: 100%;
  padding: 10px;
  background-color: #011f49;
  height: auto;
  min-height: 100px;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.column_visitor > li {
  margin: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 10px;
  list-style-type: none;
}

.column_visitor {
}

.column_visitor > li {
}

/* *************** responsive part *************** */
.user_cart {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-right: 5rem;
}

/* .user_cart > div {
  flex: 1; 
} */

.user_login {
  position: relative;
  right: 15%;
}

.users_initial_dropdown:hover .users_dropdown {
  display: block;
}

/* .users_initial_dropdown {
  float: left;
  overflow: hidden;
  padding-left: 3rem;
} */

.mining_cart {
  margin-left: 2rem;
}

/* @media only screen and (max-width:800px) {

  .header_con {
    width: 80%;
    padding: 0;
  }

}

@media only screen and (max-width:500px) {

  .header_con {
    width: 100%;
  }
} */

@media (min-width: 340px) and (max-width: 500px) {
  .logo_con {
    width: 300px !important;
    margin: 0 auto;
    padding-top: 20px;
  }

  .input_search {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-left: 0px;
  }

  .user_cart {
    flex-direction: row;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .scroll-to-top {
    position: fixed;
    bottom: 10%;
    right: 20px;
  }

  .users_dropdown {
    right: 0;
  }

  .cart_dropdown:hover .cart_dropdown_box {
    display: none;
  }

  .quote_product_modal .modal-content {
    width: 100%;
    max-width: 350px;

    left: 0;
    margin: 0 auto;
  }
}

a {
  text-decoration: none;
  color: black;
}

.notActive {
  pointer-events: none !important;
  touch-action: none !important;
  color: gray;
  text-decoration: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.newCardsCategories {
  margin: 0 auto;
  width: 100%;
  padding: 50px 50px;
  display: block;
}

.newCardsCategoriesWrapper {
  display: flex;
  gap: 50px;
  width: 100%;
  max-width: 1620px;
  flex-wrap: wrap;
  column-gap: 70px;
}

.newCategoryImg {
  width: 100%;
  max-width: 175px;
  height: auto;
  position: absolute;
  right: -50px;
  top: 55px;
}

.newTitleCategory {
  margin-bottom: 30px;
  font-weight: bold;
}

.category_grid__wrap {
  max-width: 1570px;
  margin: 0 auto;
  grid-gap: 15px;
  padding: 15px;
  display: flex;
  direction: ltr;
  color: white;
}

.category_grid__single {
  background-size: cover;
  background-position: center;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  grid-column: span 4;
  height: 300px;
  padding: 30px 25px;
  background-color: #1e4881;
  transition: 0.3s ease;
  position: relative;
  width: 100%;
  flex: 350px;
}

.category_grid__single:hover {
  flex: 850px;
}

.category_grid__menu_link:hover {
  color: black;
}

.category_grid__img {
  width: 30%;
  height: auto;
  position: absolute;
  right: -35px;
  top: 50%;
  transition: 0.3s;
  transform: translateY(-50%);
}

.category_grid__text .category_grid__menu {
  align-self: center;
  justify-self: center;
}

.category_grid__text_wrap {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.category_grid__text {
  width: 100%;
}

.category_grid__title {
  font-size: 21px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 700;
  padding-top: 0;
  transition: 0.5s ease;
}

a .category_grid__title {
  color: white;
}

.category_grid__menu {
  position: absolute;
  left: 50px;
  top: 50px;
  right: 0;
  height: 400px;
  width: 100%;
  transition: 0.3s ease;
  overflow: hidden;
}

.category_grid__menu_wrap {
  position: absolute;
  top: -185px;
  left: 20px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  height: 200px;
  flex-wrap: wrap;
  transition: 0.5s ease;
  opacity: 0;
}

.category_grid__single:hover .category_grid__menu_wrap {
  top: 0;
  opacity: 1;
}

.category_grid__single:hover .category_grid__title {
  transform: translateY(-20px);
  font-size: 18px;
  text-decoration: underline;
}

.category_grid__single:hover
  .category_grid__single:has(+ .category_grid__single) {
  background-color: #1e4881;
}

.category_grid__menu_link {
  font-size: 16px;
  color: #f5f5f5;
}

.category_grid__link {
  font-size: 8px;
  font-weight: 700;
  transition: 0.2s;
  text-transform: uppercase;
  z-index: 100;
}

.category_grid__link span {
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
}

.category_grid__single:hover .category_grid__img {
  transform: translateY(-25%) scale(1.1);
}

.category_grid__link:hover span,
.category_grid__link:hover i,
.category_grid__link:hover {
  color: black;
  margin-right: 10px;
}

@media screen and (max-width: 1600px) {
  .category_grid__single {
    flex: 200px;
  }
}

@media screen and (max-width: 1230px) {
  .category_grid__single {
    flex: 100px;
  }
}

@media (max-width: 1024px) {
  .category_grid__single {
    flex: 200px;
    padding: 15px 20px;
    height: 200px;
  }

  .category_grid__single:hover .category_grid__title {
    transform: translateY(-5px);
    font-size: 14px;
  }

  .category_grid__menu_wrap {
    height: 120px;
  }

  .category_grid__menu_link {
    font-size: 12px;
  }

  .category_grid__img {
    width: 25%;
  }

  .category_grid__single:hover .category_grid__img {
    transform: translateY(-45%) scale(1.1);
  }
}

@media (max-width: 500px) {
  .category_grid__single:hover {
    height: 350px;
  }

  .category_grid__menu {
    overflow: scroll;
    height: 250px;
  }

  .category_grid__menu_wrap {
    height: fit-content;
  }
}

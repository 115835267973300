.addToCartBtn {
  width: 106px;
  margin-left: 3rem;
}

.addToCartQty {
  width: 120px;
}

.PriceContact {
  font-size: 1.5rem;
}

/* preview product modal */
/* bootstrap carousel */
.preview_carousel .carousel-control-prev-icon,
.preview_carousel .carousel-control-next-icon,
.carousel-indicators .slide {
  background-color: #1e4881;
}

.preview_carousel .carousel-control-prev,
.preview_carousel .carousel-control-next {
  color: #1e4881;
}

.preview_carousel .carousel-indicators > button {
  background-color: #1e4881;
}

.preview_carousel .carousel-indicators .active {
  background-color: #1e4881;
}
/* my-gallery */
.my-gallery .image-gallery-thumbnail {
  border: 2px solid #74bbf8;
  width: 10%;
}

.my-gallery .active {
  border: 2px solid #1e4881;
}

.my-gallery .image-gallery-thumbnail-image {
  display: none;
}

.product_description button {
  color: #1e4881;
}

.edite_product_short_infor .modal-content {
  width: 200%;
  margin-left: -50%;
}

.btn_blue {
  background-color: #073474;
}
.btn_blue:hover {
  background-color: #3a74c5;
}
.categoryHD {
  margin-top: 0%;
  margin-left: 0.5%;
  color: gray;
  cursor: text;
  opacity: 0.5;
  font-size: medium;
}

.btnLogin {
  text-align: center;
}

.btnsLoginRegistration {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.btnsLoginRegistrationProductPage {
  display: flex;
  gap: 5px;
  width: 100%;
}

.btnLogin button {
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 70px;
}

.btnLoginProductPage {
  display: flex;
  gap: 5px;
}

.thumbnailClass {
  width: 100px;
  height: 110px;
}

.thumbnailClass img {
  object-fit: fill;
  width: 35% !important;
}

.product-details-page-description {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 320px;
  align-items: center;
}

.product-details-page-description .col {
  flex: none;
}

.stock-item i {
  animation: blinking 2s ease-in-out infinite;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

@keyframes blinking {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.min-500-order {
  text-align: center;
  border: 1px solid rgba(13, 110, 253, 0.2);
  color: #0d6efd;
  margin-bottom: 25px;
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
  background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4));
  border-radius: 5px;
}

.min-500-order i {
  color: #0d6efd;
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 1440px) {
  .btnLogin button {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .login_mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .login_desktop {
    display: none;
  }
}

@media (max-width: 1024px) {
  .addTocartBtn {
    margin-left: 35px;
  }
}

@media (max-width: 480px) {
  .preview-product-row {
    font-size: 10px;
  }

  .producr-detail-page-spec-item {
    font-size: 12px;
    line-height: 18px !important;
  }

  .product-detail-page {
    margin: 0px !important;
  }

  .product-detail-page-row {
    justify-content: center;
    align-items: center;
  }

  .product-detail-page-list-group {
    padding: 0px;
  }

  .product-detail-page-info-row {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .product-detail-page-title {
    font-size: 14px;
  }

  .product-detail-page-spec-descrp {
    margin-top: 0px !important;
  }

  .product-detail-page-spec-descrp div {
    padding: 0px;
    font-size: 12px !important;
  }

  .uniform-product-page {
    padding: 0px 0px !important;
    width: 100%;
    max-width: 350px;
    margin: 0 auto !important;
  }

  .product-details-page-description {
    flex-direction: column;
  }

  .product-list-page-wrapper {
    width: 100% !important;
    max-width: 340px;
    margin: 0 auto !important;
  }
}

.loading-spinner {
  width: 15% !important;
}

.min-500-order {
  padding: 7px 2px;
}

.min-500-order,
.min-500-order i {
  font-size: 12px;
}
.colKey {
  width: 120px;
  padding-left: 10px;
  padding-top: 5px;
}
.colValue {
  width: 250px;
  padding-left: 10px;
}
.productTable {
  width: 400px;
  border-radius: 2%;
  background-color: rgb(231, 229, 229);
}

.wrapper {
    position: relative;
    white-space: nowrap;
    padding: 0;
    will-change: transform;
    display:flex;
    gap: 10px;
    margin-right: 10px;
    z-index: 0;
  }

@media (max-width: 480px) {
  .wrapper {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

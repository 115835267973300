/* ******************** HEADER ******************** */
.visitor_header_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.logo_container {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-links {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Mobile styles */
.logo_container img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .visitor_header .mobile_top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .visitor_header .mobile_top_section .logo_container {
    flex-grow: 1;
  }

  .visitor_header .mobile_top_section h4 {
    margin-left: 20px;
    font-size: 14px;
    flex-grow: 0;
    text-align: right;
  }

  .login_register_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input_search_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #search_input {
    flex-grow: 1;
  }

  #rfq_button {
    max-width: 50px;
    /* margin-left: 10px; */
  }

  .red_search_img_visitor {
    width: 2rem;
    height: 2rem;
  }
}

/* ******************** NAVB ******************** */
@media (min-width: 992px) {
  .navb_visitor_mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .navb_visitor_desktop {
    display: none;
  }

  .hd_bgc_mobile {
    background-image: url("../../../../../public/images/HeaderBackgroundImage_mobile.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .nav_bgc {
    overflow-x: auto;
    white-space: nowrap;
  }

  .navb_visitor_mobile {
    /* overflow-x: hidden; */
  }

  .dropbtn_mobile {
    color: white;
    margin: 2px 10px;
  }

  .dropbtn_mobile:hover + .dropdown_content_mobile,
  .dropdown_content_mobile:hover {
    display: block;
  }

  .dropbtn_mobile:active {
    opacity: 0.7;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.added-to-cart-message {
    position: fixed;
    top:-140px;
    left: 75%;
    transform: translateY(20%);
    opacity: 0;
    z-index: 9999;
    transition: opacity 2s ease-in-out, top 2s ease-in-out;
    background-color: #066d06;
    color:white;
    padding: 0 1rem;
  }
  
  .added-to-cart-message.show {
    opacity: 0.5;
    top: 22%;
  }
  
  .added-to-cart-message:hover {
    opacity: 0.5;
    top: 22%;
    transition:none;
  }
  
  
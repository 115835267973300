.newModal {
    width: 100%;
    max-width: 1800px;
    border-radius: 25px;
    margin: 0 auto;
    padding: 20px 20px;
    position: fixed;
    background-color: rgba(245, 245, 245, 1);
    height: 95vh;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);
}

.newModalTitle {
    font-weight: bold;
    color: #1E4881;padding-left: 80px;
    text-transform: uppercase;
}

.newModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    max-width: 1700px;
    margin:  0 auto;
}

.newModalHeader i {
    color: #1E4881;
    font-size: 36px;
    font-weight: bold;
    cursor: pointer;
}

.newModal button {
    border: none;
    background-color: transparent;
}

.newModalContent {
    overflow-y: auto;
    height: 80vh;
}

.newModalContent::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  .newModalContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    padding-right: 50px;
  }

  .newModalContent::-webkit-scrollbar-thumb {
    background: #1E4881;
    opacity: 0.1;
    border-radius: 10px;
  }
  
  .newModalContent::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }

  .newModalContent::-webkit-scrollbar-corner {
     display: none;
  }

  @media (max-width: 1024px) {
    .newModal {
      max-width: 900px;
      height: 80vh;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    .newModalHeader {
      max-width: 800px;
    }

    .newModalContent {
      height: 70vh;
    }

    .newModalTitle {
      padding-left: 0px;
    }
  }

  @media (max-width: 768px) {
    .newModal {
      height: 95vh;
        transform: translate(-50%, -50%);
        max-width: 700px;
        top: 50%;
        left: 50%;
    }

    .newModalContent {
      height: 80vh;
   }
  }

  @media (max-width: 500px) {
    .newModal {
      height: 95vh;
        transform: translate(-50%, -50%);
        max-width: 350px;
        top: 55%;
        left: 50%;
    }

    .newModalHeader h2, .newModalHeader i   {
      font-size: 18px;
    }

    .newModalContent {
       height: 80vh;
    }
  }

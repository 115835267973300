.aboutNew {
    padding: 50px 50px 50px 50px;
    margin: 0 auto;
    position: relative;
}

.aboutNewWrapper {
    display: flex;
    justify-content: center;
    gap: 100px;
}

.aboutNewFirstBlock {
    margin-right: 50px;
    text-align: center;
}

.aboutNewLogo {
    width: 100%;
    max-width: 307px;
    margin: 0 auto;
    padding: 25px 0px;
}

.aboutNewCtlLogo {
    width: 100%;
}

.aboutNewText {
    width: 100%;
    max-width: 350px;
    font-size: 14px;
    text-align: justify;
}

.aboutFirstSectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.aboutNewSecondBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aboutNewFirstImg {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.aboutNewFirstImg img {
    width: 100%;
    max-width: 800px;
    border-radius: 15px
}

.aboutNewSecondImg {
    width: 100%;
    max-width: 485px;
    border-radius: 15px
}

.aboutNewThirdBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 20px;
}

.aboutNew button {
    position: fixed;
    top: 80%;
    left: 150px;
    opacity: 0.5;
    transition: all 0.2s linear;
    z-index: 100;
}

.aboutNew button:hover {
    opacity: 1;
}

.aboutNewRight {
    display: flex;
    opacity: 0;
}

@media (max-width: 1440px) {

    .aboutNew {
        padding-bottom: 0px;
    }
    .aboutNewSecondImg {
        width: 100%;
        max-width: 335px;
    }
}

@media (max-width: 1200px) {
    .aboutNewSecondImg {
        width: 100%;
        max-width: 300px;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .aboutNewSecondImg {
        width: 100%;
        max-width: 400px;
    }
}

@media (max-width: 1024px) {

    .aboutNew {
        height: auto;
    }
    .aboutNewSecondImg {
        width: 100%;
        max-width: 400px;
    }

    .aboutNewRight {
        display: flex;
        align-items: center;
    }

    .aboutNewThirdBlock { 
        display: none;
    }

    .aboutNew {
        padding: 50px 50px 50px 50px;
    }

    .aboutNewLogo {
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .aboutNewText {
        padding-bottom: 0px;
    }

    .aboutNewFirstImg {
        width: 100%;
        max-width: 500px;
    }

    .aboutNewSecondImg{
        max-width: 250px;
    }
}

@media (max-width: 480px) {

    .aboutNewCtlLogo {
        width: 75%;
    }

    .aboutNewWrapper {
         flex-direction: column;
         gap: 25px;
    }

    .aboutNew {
        padding: 25px 25px 25px 25px;
    }

    .aboutNewFirstBlock {
        margin-right: 0px;
    }

    .aboutNewSecondBlock {
        margin-top: 25px;
    }

    .aboutNewLogo {
        margin-bottom: 10px;
        margin-top: 0px;
        padding: 0px;
    }

    .aboutNewText {
        max-width: 100%;
        padding-bottom: 0px;
        margin-bottom: 0px;
        font-size: 12px;
    }

    .aboutNewSecondImg {
        max-width: 200px;
    }
}

/* ******************** HEADER ******************** */
.visitor_header_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.logo_container {
  width: 25%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  z-index: 1;
}

.auth-links {
  position: absolute;
  right: 0;
  bottom: 0;
}



/* Mobile styles */
.logo_container img {
  max-width: 100%;
  height: auto;
  z-index: 1;
}


.header {
  background-color: #F8F9FA;
  padding: 0px 10px 10px 10px;
  width: 100%;

}

.header_wrapper {
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 10px;

  background-image: url("../../../public/images/HeaderBackgroundImage_mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: 10px;
  padding-right: 10px;

}

.input_search_mobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;;
}

.border_line {
  width: 100%;
  border: 1px solid #F8F9FA;
}

.hexagontext {
  width: 50%;
  margin-left: 10px;
}

.mobile_top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_register_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.search_bar {
  width: 100%;
  font-size: 10px;
  max-width: 235px;
  margin: 0 auto;
}

.search_input {
  font-size: 10px;
  border-radius: 15px;
}

.search_btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.rfq_button img {
  opacity: 0.5;
}


.modal_wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}



.modal-content {
  margin: 0 auto !important;
}

.CTL_btn {
  background-color: #1e4881;
  border: #1E4881 solid 1px;
}

.CTL_btn:hover {
  border: #5c8dd1 solid 1px;
  background-color: #5c8dd1;
}

.hd_c {
  color: #1e4881;
  font-weight: bold;
}

.hd_c:hover {
  color: black;
  cursor: pointer;

}

a {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.container
{
  text-align: justify;
}
.terms-ul{
  margin:0;
  padding:0 20px;
  list-style: disc;
} 
.terms-ul li    {
  padding:0;
  margin:10px 0;
}
.terms-ul li:hover {
	color: rgb(0, 0, 0);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* *************    Login     ***************** */
.LoginForm {
  width: 40%;
  margin-left: 30%;
}

/* *************    Register     ***************** */
.RegisterForm {
  width: 40%;
  margin-left: 30%;
}

/* *************    Login/Register     ***************** */
 .LoginRegPage {
  margin-top: -3.5%;
  margin-bottom: 1.2%;
}
.LoginReg{
  border-width: 0;
}
/*
.loginfm {
  width: 94%;
  margin-left: 3%;
  margin-top: 6%;
}

.lg_distributor {
  margin-top: 20%;
}

.distfont {
  font-size: 90%;
} */

/* *************    Nav's AAp.css     ***************** */
/*--------------------STYLES FOR LOGIN PAGE--------------------*/
.card{
  margin-top:50px;
  display:flex;
  
}

.flex-row{
display:flex;
justify-content: center;

}


/* ****************      IMG HOVER  Larger  ************** */
.img_hovf {
  overflow: hidden;
}

.img_hover:hover {
  scale: 110%;
  transition: 0.5s;
}
li:hover {
	color: rgb(0, 0, 0);
}


/* **************  common css **************** */
.ctl_blue_button {
  background-color: #1E4881;
  border: #1E4881;
}

.CTL_blue {
  color: #1E4881;
}

.CTL_blue_light {
  color: #5c8dd1;
}

/* ********** Print Layout ********** */
@media print {
  @page { size: landscape; }
}
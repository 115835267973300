.aboutNewBtn {
    border-radius: 50px;
    border: none;
    background-color: #1d69d3;
    font-weight: 700;
    color: #fff;
    padding: 15px 20px;
    font-size: 18px;
    position: fixed;
    top: 80%;
    left: 150px;
    opacity: 0.5;
    transition: all 0.2s linear;
    z-index: 100;
    transition: 0.3s ease-in-out;
}

.visible {
    visibility: visible;
}
.unvisible {
    visibility: hidden;
}

.aboutNewBtn:hover {
    opacity: 1;
}

@media (max-width:1024px) {
    .aboutNewBtn {
        left: 50px;
        top: 50%;
    }
}

@media (max-width: 480px) {
    .aboutNewBtn {
        font-size: 14px;
        top: 80%;
    left: 50%;
    transform: translate(-50%, 80%);
    }
}

.invoicePDF {
  display: none;
}

.hiden {
  display: none;
}

.Re_Order_Modal {
  position: fixed;
  top: 20%;
}

.showingout {
  animation: showingout 1s;
}

.mobile {
  display: none;
}

.mobile-visibility {
  display: none;
}

@keyframes showingout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.button-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;
}

.reorder-button {
  animation: flyout 1s forwards;
}

@keyframes flyout {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  50% {
    opacity: 0.5;
    transform: translate(90vw, -260vh);
  }

  100% {
    opacity: 0;
    transform: translate(140vw, -520vh);
  }
}

.cart_detail_right {
  margin-top: 0.7rem;
}

.empty_cart_btn {
  border-radius: 0;
  background-color: #1e4881;
  border: 1px solid #1e4881;
  color: white;
  float: right;
}

.empty_cart_btn:hover {
  color: #1e4881;
  border: 1px solid #1e4881;
  background-color: white;
}

.download_cart_btn {
  color: white;
  border-radius: 0;
  margin-right: 0.5rem;
}

.download_cart_btn:hover {
  color: #1e4881;
  border: 1px solid #1e4881;
  background-color: white;
}

/* ********* edit order name ********* */
.edite_order_name .modal-content {
  margin-top: 60%;
}

/************** add ownership ********************/
.add_ownership .modal-content {
  margin-top: 60%;
}

/************** User_Quote_List_Tabs ********************/
.User_Quote_List_Tabs .nav-link.active {
  background-color: #1e4881;
  font-weight: bold;
  color: #fff;
  box-shadow: inset 0 0 0 1px #1e4881;
}

.User_Quote_List_Tabs .nav-link {
  font-weight: bold;
  color: #1e4881;
  background-color: rgb(243, 243, 243);
  box-shadow: inset 0 0 0 1px #d2dce6;
}

/************** USER SIDE NAV ********************/

.navbar-nav .nav-link.active {
  background-color: #5c8dd1;
  font-weight: bold;
  color: #fff;
}

.navbar-nav .nav-link {
  font-weight: bold;
  color: #1e4881;
}

.navbar-nav .nav-link:hover {
  font-weight: bold;
  background-color: #b0c1d8;
  color: #1e4881;
}

.user_side_nav {
  width: 100%;
}

.user_side_navBar {
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  background-color: rgb(243, 243, 243);
}

.user_side_navBar .nav-link {
  width: 175px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.user_side_nav {
  width: auto;
}

.admin_side_navBar {
  width: fit-content;
  margin-right: auto;
  margin-left: 0;
  border-radius: 10px;
  background-color: rgb(243, 243, 243);
}

.admin_side_navBar .nav-link {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.clickable {
  cursor: pointer;
}

@media (max-width: 820px) {
  .userCartDetailPage .cart-item {
    width: 33%;
  }

  .userCartDetailPage .cart-items-list {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .userCartDetailPage div {
    width: 100%;
  }

  .userCartDetailPage img {
    width: 100%;
    max-width: 250px;
  }
  .userCartDetailPage .form-control-container {
    width: 100%;
    max-width: 150px;
  }

  .userCartDetailPage .remove-from-cart {
    width: 100%;
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .mobile-visibility {
    display: block;
  }
  .noQuotesFoundText {
    font-size: 14px !important;
  }

  .userCartDetailPage {
    font-size: 12px;
    width: 100%;
    max-width: 350px;
  }

  .userCartDetailPage h1,
  .userCartDetailPage h4 {
    font-size: 18px;
  }

  .userCartDetailPage .cart-items-list {
    flex-direction: column;
  }

  .userCartDetailPage .cart-item {
    width: 100%;
  }
  .userCartDetailPage img {
    width: 100%;
    max-width: 150px;
  }

  .userCartDetailPage p {
    margin-bottom: 0px;
  }

  .userCartDetailPage .form-control-container {
    width: 100%;
    max-width: 150px;
  }

  .userCartDetailPage .remove-from-cart {
    width: 100%;
    max-width: 150px;
  }

  .userCartDetailPage button,
  .userCartDetailPage a,
  .userCartDetailPage .input-group-text,
  .userCartDetailPage .form-control {
    font-size: 12px;
  }

  .userCartDetailPage .cart_detail_right {
    margin-top: 0px;
  }

  .userCartDetailPage .list-group-item {
    padding: 0px 25px;
    margin-top: 0px !important;
    padding-bottom: 10px;
  }

  .email_cart_btn_wrapper {
    justify-content: flex-start !important;
    margin-left: -12px;
    max-width: 100px !important;
  }

  .download_cart_btn_wrapper {
    max-width: 150px !important;
  }
}

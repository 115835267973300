.splashpage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 120%;
  background-image: url("../../public/images/DeepBlueBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: -270px;
  padding-bottom: 15rem;
}

.splashpagewrapper {
  height: 100vh;
  background-image: url("../../public/images/DeepBlueBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginRegWrapper {
  width: 50%;
}

.loginReg {
  width: 90%;
  margin-top: 5%;
  padding-bottom: 5rem;
}

/*white map only*/
.redAUS {
  z-index: 0;
  width: 95%;
}

.message-btn {
  position: absolute;
  top: 10%;
  justify-content: center;
}

.message {
  position: absolute;
  top: -15%;
}

.unfortunately_modal_content {
  width: 100rem;
}

/* ************* Unfortunately ************* */
.CTLunfortunately {
  background-image: url("../../public/images/DeepBlueBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: -226px;
  height: 100vh;
}

.btnUnfor {
  margin-top: -15%;
}

/* *************    middle screen     ************* */
@media only screen and (max-width: 820px) {
  .splashpage {
    display: grid;
    grid-template-columns: 40% 60%;
    padding-bottom: 15rem;
  }

  .loginReg {
    width: 100%;
    padding-bottom: 5rem;
  }

  .redAUS {
    width: 100%;
    margin-top: 20%;
  }
}

/* *************    middle screen     ************* */
@media only screen and (max-width: 415px) {
  .splashpage {
    display: block;
    padding-bottom: 5rem;
  }

  .loginReg {
    width: 100%;
  }

  .redAUS {
    margin-left: 22%;
    margin-top: 0;
    margin-bottom: 0;
    width: 50%;
  }
}

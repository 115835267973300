
  .hamburger {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .hamburger::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    transform: translateY(-10px);
    box-shadow: 0 10px #1e4881;
    transition: 0.3s;
    background-color: #1e4881;
    border-radius: 2px;
  }
  
  .hamburger::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: #1e4881;
    transform: translateY(10px);
    transition: 0.3s;
    border-radius: 2px;
    display: block;
  }
  
  .hamburger.clicked {
    transition: 0.3s;
  }
  
  .hamburger.clicked::before {
    transform: translateY(0px) rotate(45deg);
    box-shadow: 0 0px #1e4881;
  }
  
  .hamburger.clicked::after {
    transform: translateY(0px) rotate(-45deg);
    box-shadow: 0 0px #1e4881;
  }

  .sidebar {
    width: 100%;
    max-width: 300px;
    background-color: #1e4881;
    opacity: 0.9;
    color: #fff;
  }

  .sidebar_header {
    display: flex;
    justify-content: flex-end;
  }

  .sidebar_header svg {
    width: 30px;
    height: 30px;
  }

  .categories_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .acc_body {
    color: white;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    padding-bottom: 10px;
  }

  .acc_btn {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    transition: transform 280ms ease-out 50ms;
  }

  .acc_btn svg {
    width: 20px;
    height: 20px;
  }

  .acc_btn_name {
    padding-left: 10px;
  }

  .svg_rotate {
    display: inline-block;
    animation: rotation 0.2s  forwards;
  }

  .svg_rotate_back {
    animation: rotation_back 0.2s  forwards;
  }


  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes rotation_back {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .rfq_button {
    display: none;
  }


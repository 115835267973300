.blocksNew {
    background: linear-gradient(180deg, #59b7e4 0%, #1e4881 100%);
    padding: 100px 50px;
    overflow: hidden;
    height: 90vh;
}

.promotionBlockTopNew {
    display: flex;
    gap: 50px;
    margin-bottom: 25px;
    align-items: stretch;
    justify-content: center;
}

.promotionBlockNewVideo {
    width: 100%;
    border-radius: 5px;
}

.promotionBlockNewVideo video {
    width: 100%;
    border-radius: 5px;
    object-fit: fill;
    height: 100%;
}

.promotionBlockNewImage {
    width: 100%;
    border-radius: 5px;
    transition: all 0.1s ease-in;
    cursor: pointer;
    object-fit: fill;
}

.promotionBlockBottomNew {
    display: flex;
    gap: 50px;
    align-items: stretch;
    justify-content: center;
}

.newBlockItem {
    max-width: 445px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.textBlock {
    align-self: center;
}

.topLine {
    transform: translateX(100%);
}
.bottomLine {
    transform: translateX(100%);
}

.mobile {
    display: none;
}


@media (max-width: 1024px) {
    .blocksNew {
        padding: 40px 50px;
        height: auto;
    }

    .promotionBlockTopNew {
        gap: 25px;
    }


}

@media (max-width: 480px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .blocksNew {
        padding: 25px 20px;
    }

    .mobileNewBlockTexts {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 15px;
    }

    .mobileNewBlockImages {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .mobileNewBlockImages .newBlockItem {
        max-width: 155px;
    }

    .promotional_video {
        width: 100%;
        height: 200px !important;
    }
    
    .promotional_video video {
        object-fit: fill !important;
    
    }
}

.newBannerBlock {
  width: 100%;
  padding: 10px 10px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.fullWidth {
  border: 25px;
  flex: 1;
  transition: flex 0.8s ease;
}

.halfWidth {
  flex: 0.05;
  transition: flex 0.8s ease;
}

.fullWidth img {
  width: 100%;
  border-radius: 25px;
  height: 400px;
  object-fit: fill;
  cursor: pointer;
}

.halfWidth img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  cursor: pointer;

  border-radius: 25px;
}

.newBannersBtns {
  display: flex;
  justify-content: flex-end;
  padding: 0px 40px 0px 0px;
}

.newBannersBtns button {
  border: none;
  background-color: transparent;
}

.newBannersBtns button i {
  font-size: 30px;
  color: #073474;
}

.newPromotionBannersWrapper {
  padding: 50px 0px 25px 0px;
}

.mobile {
  display: none;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .newPromotionBannersWrapper {
    padding: 25px 0px 25px 0px;
  }

  .halfWidth {
    flex: 0.1;
  }

  .fullWidth img {
    height: 300px;
  }

  .halfWidth img {
    height: 300px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .newPromotionBannersWrapper {
    padding: 15px 0px 25px 0px;
  }

  .halfWidth {
    flex: 0.2;
  }

  .fullWidth img {
    height: 200px;
  }

  .halfWidth img {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .newPromotionBannersWrapper {
    padding: 10px 0px 10px 0px;
  }

  .halfWidth {
    flex: 0;
  }

  .fullWidth img {
    height: 200px;
  }

  .halfWidth img {
    height: 200px;
  }
}

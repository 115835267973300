.homeNew {
    height: calc(100vh - 108px);
    position: relative;
}

.homeNew button {
        position: fixed;
        bottom: 50px;
        left: 145px;
        opacity: 0.5;
        transition: all 0.5s ease-in-out;
        border-radius: 50px;
        z-index: 100;
}

.homeNew button:hover {
    opacity: 1;
}

.bgVid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity:0.5;
}

.videoMain {
    width: 100%;
    height: 100%;
}

.videoMain video {
    object-fit: cover;
    opacity: 0.5;
}

.mobile {
    display: none;
}

@media (max-width: 1024px) {
    .homeNew {
        height: calc(60vh - 90px);
    }
}

@media (max-width: 480px) {
    .homeNew {
        /* max-height: 50vh; */
        height: calc(100vh - 90px);
    }

    .mobile {
        display: block;
    }
}

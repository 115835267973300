.welcome-main {
    display: flex;
    text-align: center;
    padding: 20px 0px; 
    flex-direction: column; 
    margin: 0 auto; 
    background-color: #e9f3ff;
}

.welcome-main h4 {
    padding-bottom: 10px;
}

.br-visibility {
    display: none;
}

@media (max-width:600px) {
    .welcome-main {
        font-size: 12px;
        padding: 20px 20px;
    }

    .welcome-main h4 {
        font-size: 16px;
    }

    .br-visibility {
        display: inline-block;
    }
}
.newRequestForm {
    padding: 50px 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.newRequestFormWrapper {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.newRequestForm h2, .frequentlyAskedQuestions h2 {
     margin-bottom: 25px;
}

.requestForm {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    justify-content: center;
    max-width: 700px;
}

label {
    font-weight: bold;
    color: #073474;
}

.nameEmail {
     display: flex;
     gap: 10px;
     align-items: center;
}

.nameEmail div {
    width: 50%;
}

.typeEnquiry {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.typeEnquiryFields {
    display: flex;
    gap: 5px;
    flex-direction: column;

}

.requestForm input, .requestForm select, .requestForm textarea {
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #073474;
    color: #073474;
}

.requestForm textarea {
    margin-bottom: 20px;
}

input {
    caret-color: #073474;
}

::placeholder {
    color: #073474;
    opacity: 0.5;
}

.requestForm input[type=file] {
    border: none;
    padding: 0px;
}

input[type=file]::file-selector-button {
    background-color: #fff;
    color: #073474;
    border: 1px solid #073474;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
    cursor: pointer;
    border-radius: 5px;
}

input[type=file]::file-selector-button:hover {
    background-color: #073474;
    color: #fff;
    border: 1px solid #073474;
}

.selectedFileImg {
    width: 85px;
    height: auto;
}

.newRequestSent {
    display: flex;
    flex-direction: column;
}
.newRequestFormBtn {
    width: 200px;
    padding: 5px 0px;
    border: 1px solid #073474;
    background-color: #073474;
    color: #fff;
    border-radius: 5px;
}

.frequentlyAskedQuestions {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    color: black;
}

.categories_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .acc_btn_name {
    padding-left: 10px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: black;
    text-align: start;
  }

  .acc_body {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    padding-bottom: 10px;
  }

  .acc_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    transition: transform 280ms ease-out 50ms;
  }

  .acc_btn svg {
    width: 20px;
    height: 20px;
    color: black;
  }

  .svg_rotate {
    display: inline-block;
    animation: rotation 0.2s  forwards;
  }

  .svg_rotate_back {
    animation: rotation_back 0.2s  forwards;
  }

  .accordionItem {
    border-top: 1px solid var(--ux-97h3vl, lightgray);
    cursor: pointer;
    padding: 20px 0px 0px 0px;
    width: 100%;
    max-width: 560px;
  }

  @media (max-width: 1024px) {
    .newRequestForm {
        padding: 25px 25px;
        gap: 25px;
    }

    .newRequestFormWrapper h2 {
        font-size: 22px;
    }

    .nameEmail {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }

    .nameEmail div {
        width: 100%;
    }

    .frequentlyAskedQuestions h2 {
        font-size: 22px;
    }

    .categories_list {
        font-size: 12px;
    }

    .acc_btn_name {
        font-size: 16px;
    }

    .requestForm input, .requestForm select, .requestForm textarea {
        padding: 5px 10px;
    }

    .acc_btn {
        padding-bottom: 10px;
    }

    .accordionItem {
        padding: 10px 0px 0px 0px;
    }
  }

  @media (max-width: 480px) {
    .newRequestForm {
        flex-direction: column-reverse;
        gap: 0px;
    }

    .frequentlyAskedQuestions {
        margin-bottom: 25px;
    }

    .newRequestFormWrapper h2, .frequentlyAskedQuestions h2 {
        margin-bottom: 10px;
        font-size: 18px;
    }

    .categories_list {
        font-size: 12px;
    }

    .acc_btn_name {
        font-size: 12px;
        text-align: start;
    }

    .acc_btn svg {
        width: 15px;
        height: 15px;
    }

    .acc_body {
        padding-left: 30px;
        font-size: 10px;
        padding-bottom: 10px;
    }

    .acc_btn {
        padding-bottom: 5px;
    }

    .requestForm {
        max-width: 350px;
    }

    label, select,.requestForm input[type=file] {
        font-size: 12px;
    }

    .newRequestFormBtn {
        width: 100px;
        font-size: 12px;
    }
}
